<nav
    class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between"
    *ngIf="breadcrumbs$ | async as breadcrumbs"
    aria-label="breadcrumb"
>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 pl-0">
        <li class="breadcrumb-item position-relative">
            <a routerLink="/" class="d-inline-block position-relative">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>

        <li
            *ngFor="let breadcrumb of breadcrumbs; let last = last"
            class="breadcrumb-item position-relative"
            [ngClass]="{ active: last }"
        >
            <a
                class="d-inline-block position-relative pl-0"
                *ngIf="!last; else lastBreadcrumb"
                [routerLink]="breadcrumb.url"
                >{{ breadcrumb.label }}</a
            >

            <ng-template
                class="d-inline-block position-relative"
                #lastBreadcrumb
                >{{ breadcrumb.label }}</ng-template
            >
        </li>
    </ol>
</nav>
