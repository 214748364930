<div *ngIf="isToggled">
    <div id="loader">
        <div class="main-svg">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xmlnsSerif="http://www.serif.com/"
                viewBox="301.20587158203125 232.9704132080078 397.5836181640625 534.0554809570312"
                version="1.1"
                xml:space="preserve"
                width="150px"
                y="124.2299976348877"
                x="201.891"
                preserveAspectRatio="xMinYMin"
                fill-rule="evenodd"
                clip-rule="evenodd"
                stroke-linejoin="round"
                stroke-miterlimit="2"
                style="overflow: visible"
            >
                <path
                    transform="matrix(1.26029,0,0,1.26029,-428.124,-124.652)"
                    d="M637.932,606.358L637.932,469.167L708.522,469.167L578.7,283.762L665.172,283.762L790.992,463.452C796.71,460.687 801.982,456.965 806.558,452.388C817.301,441.645 823.337,427.075 823.337,411.882L823.337,411.88C823.337,396.687 817.301,382.117 806.558,371.374C795.815,360.631 781.245,354.596 766.052,354.596L734.77,354.596L685.172,283.762L766.052,283.762C800.031,283.762 832.618,297.26 856.645,321.287C880.672,345.314 894.17,377.901 894.17,411.88L894.17,411.882C894.17,445.861 880.672,478.448 856.645,502.475C832.618,526.502 800.031,540 766.052,540L708.766,540L708.766,707.518L637.932,606.358Z"
                    class="color-change-2x"
                />
            </svg>
        </div>
    </div>
</div>
