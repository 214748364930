import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    GuardResult,
    MaybeAsync,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { StorageManagerService } from './storage-manager.service';
import {
    PermissionAction,
    PermissionCategory,
    Permissionss,
    permission,
} from './authentication.service';

@Injectable()
export class PermissionGuard implements CanActivate {
    constructor(private _router: Router) {}
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        const requiredPermission = route.data['requiredPermission'] as {
            category: PermissionCategory;
            action: PermissionAction;
        };
        if (requiredPermission) {
            const userPermissions = StorageManagerService.getPermissions();
            const hasPermission = this.checkPermission(
                userPermissions,
                requiredPermission
            );
            if (!hasPermission) {
                this._router.navigate(['/']);
                return false;
            }
        }

        return true;
    }
    private checkPermission(
        userPermissions: permission,
        requiredPermission: {
            category: PermissionCategory;
            action: PermissionAction;
        }
    ): boolean {
        const { category, action } = requiredPermission;
        if (userPermissions.permissions[category][action] == true) {
            return true;
        } else {
            return false;
        }
    }
}
