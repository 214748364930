import { ViewUserComponent } from './pages/users/view-user/view-user.component';
import { Routes } from '@angular/router';
import { AuthGuard } from './core/authentication/auth.guard';
import {
    PermissionAction,
    PermissionCategory,
} from './core/authentication/authentication.service';
import { PermissionGuard } from './core/authentication/permission.guard';

export const routes: Routes = [
    {
        path: 'login',
        loadComponent: () =>
            import('./pages/authentication/sign-in/sign-in.component').then(
                (m) => m.SignInComponent
            ),
    },
    {
        path: 'sign-up',
        loadComponent: () =>
            import('./pages/authentication/sign-up/sign-up.component').then(
                (m) => m.SignUpComponent
            ),
    },
    {
        path: 'forgot-password',
        loadComponent: () =>
            import(
                './pages/authentication/forgot-password/forgot-password.component'
            ).then((m) => m.ForgotPasswordComponent),
    },
    {
        path: 'reset-password/:token',
        loadComponent: () =>
            import(
                './pages/authentication/reset-password/reset-password.component'
            ).then((m) => m.ResetPasswordComponent),
    },
    {
        path: 'reset-password',
        loadComponent: () =>
            import(
                './pages/authentication/reset-password/reset-password.component'
            ).then((m) => m.ResetPasswordComponent),
    },
    {
        path: 'lock-screen',
        loadComponent: () =>
            import(
                './pages/authentication/lock-screen/lock-screen.component'
            ).then((m) => m.LockScreenComponent),
    },
    {
        path: 'confirm-email',
        loadComponent: () =>
            import(
                './pages/authentication/confirm-email/confirm-email.component'
            ).then((m) => m.ConfirmEmailComponent),
    },
    {
        path: 'logout',
        loadComponent: () =>
            import('./pages/authentication/logout/logout.component').then(
                (m) => m.LogoutComponent
            ),
    },

    {
        path: 'my-profile',
        canActivate: [AuthGuard],
        loadComponent: () =>
            import('./pages/my-profile/my-profile.component').then(
                (m) => m.MyProfileComponent
            ),
        data: { breadcrumb: 'My Profile' },
    },
    {
        path: 'settings',
        loadComponent: () =>
            import('./pages/settings/settings.component').then(
                (m) => m.SettingsComponent
            ),
        children: [
            {
                path: '',
                loadComponent: () =>
                    import(
                        './pages/settings/account-settings/account-settings.component'
                    ).then((m) => m.AccountSettingsComponent),
            },
            {
                path: 'change-password',
                loadComponent: () =>
                    import(
                        './pages/settings/change-password/change-password.component'
                    ).then((m) => m.ChangePasswordComponent),
            },
            {
                path: 'connections',
                loadComponent: () =>
                    import(
                        './pages/settings/connections/connections.component'
                    ).then((m) => m.ConnectionsComponent),
            },
            {
                path: 'privacy-policy',
                loadComponent: () =>
                    import(
                        './pages/settings/privacy-policy/privacy-policy.component'
                    ).then((m) => m.PrivacyPolicyComponent),
            },
            {
                path: 'terms-conditions',
                loadComponent: () =>
                    import(
                        './pages/settings/terms-conditions/terms-conditions.component'
                    ).then((m) => m.TermsConditionsComponent),
            },
        ],
    },
    {
        path: 'users',
        canActivate: [AuthGuard],
        data: { breadcrumb: 'Users' },
        children: [
            {
                path: '',
                canActivate: [PermissionGuard],
                data: {
                    breadcrumb: 'List',
                    requiredPermission: {
                        category: PermissionCategory.USERS,
                        action: PermissionAction.VIEW,
                    },
                },
                loadComponent: () =>
                    import('./pages/users/users.component').then(
                        (m) => m.UsersComponent
                    ),
            },
            {
                path: 'add',
                canActivate: [PermissionGuard],
                data: {
                    breadcrumb: 'Add',
                    requiredPermission: {
                        category: PermissionCategory.USERS,
                        action: PermissionAction.MANAGE,
                    },
                },
                loadComponent: () =>
                    import(
                        './pages/users/manage-user/manage-user.component'
                    ).then((m) => m.ManageUserComponent),
            },
            {
                path: 'edit/:id',
                canActivate: [PermissionGuard],
                loadComponent: () =>
                    import(
                        './pages/users/manage-user/manage-user.component'
                    ).then((m) => m.ManageUserComponent),
                data: {
                    breadcrumb: 'Edit',
                    requiredPermission: {
                        category: PermissionCategory.USERS,
                        action: PermissionAction.MANAGE,
                    },
                },
            },
            {
                path: 'view/:id',
                canActivate: [PermissionGuard],
                loadComponent: () =>
                    import('./pages/users/view-user/view-user.component').then(
                        (m) => m.ViewUserComponent
                    ),
                data: {
                    breadcrumb: 'View',
                    requiredPermission: {
                        category: PermissionCategory.USERS,
                        action: PermissionAction.VIEW,
                    },
                },
                children: [
                    {
                        path: '',
                        canActivate: [PermissionGuard],
                        loadComponent: () =>
                            import(
                                './pages/users/view-user/profile/profile.component'
                            ).then((m) => m.ProfileComponent),
                        data: {
                            breadcrumb: 'Profile',
                            requiredPermission: {
                                category: PermissionCategory.USERS,
                                action: PermissionAction.VIEW,
                            },
                        },
                    },
                ],
            },
            {
                path: 'password-reset/:id',
                loadComponent: () =>
                    import(
                        './pages/users/reset-password-user/reset-password-user.component'
                    ).then((m) => m.ResetPasswordUserComponent),
            },
        ],
    },
    {
        path: 'return',
        canActivate: [AuthGuard],
        data: { breadcrumb: 'return' },
        children: [
            {
                path: '',
                canActivate: [AuthGuard],
                data: { breadcrumb: 'List' },
                loadComponent: () =>
                    import(
                        './pages/return/return-list/return-list.component'
                    ).then((m) => m.ReturnListComponent),
            },
            {
                path: 'view/:id',
                canActivate: [AuthGuard],
                loadComponent: () =>
                    import(
                        './pages/return/return-view/return-view.component'
                    ).then((m) => m.ReturnViewComponent),
                data: {
                    breadcrumb: 'View',
                },
                children: [
                    {
                        path: '',
                        canActivate: [AuthGuard],
                        loadComponent: () =>
                            import(
                                './pages/return/return-view/return-details/return-details.component'
                            ).then((m) => m.ReturnDetailsComponent),
                        data: {
                            breadcrumb: 'Details',
                        },
                    },
                ],
            },
        ],
    },
    {
        path: 'client',
        canActivate: [AuthGuard],
        data: { breadcrumb: 'Client' },
        children: [
            {
                path: '',
                canActivate: [PermissionGuard],
                loadComponent: () =>
                    import('./pages/clients/clients.component').then(
                        (m) => m.ClientsComponent
                    ),
                data: {
                    breadcrumb: 'List',
                    requiredPermission: {
                        category: PermissionCategory.CLIENTS,
                        action: PermissionAction.VIEW,
                    },
                },
            },
            {
                path: 'add',
                canActivate: [PermissionGuard],
                loadComponent: () =>
                    import(
                        './pages/clients/manage-client/manage-client.component'
                    ).then((m) => m.ManageClientComponent),
                data: {
                    breadcrumb: 'Add Client',
                    requiredPermission: {
                        category: PermissionCategory.CLIENTS,
                        action: PermissionAction.MANAGE,
                    },
                },
            },
            {
                path: 'edit/:id',
                canActivate: [PermissionGuard],
                loadComponent: () =>
                    import(
                        './pages/clients/manage-client/manage-client.component'
                    ).then((m) => m.ManageClientComponent),
                data: {
                    breadcrumb: 'Edit Client',
                    requiredPermission: {
                        category: PermissionCategory.CLIENTS,
                        action: PermissionAction.MANAGE,
                    },
                },
            },
            {
                path: 'view/:id',
                canActivate: [PermissionGuard],
                loadComponent: () =>
                    import(
                        './pages/clients/view-client/view-client.component'
                    ).then((m) => m.ViewClientComponent),
                data: {
                    breadcrumb: 'View',
                    requiredPermission: {
                        category: PermissionCategory.CLIENTS,
                        action: PermissionAction.VIEW,
                    },
                },
                children: [
                    {
                        path: '',
                        canActivate: [PermissionGuard],
                        loadComponent: () =>
                            import(
                                './pages/clients/view-client/profile/profile.component'
                            ).then((m) => m.ProfileComponent),
                        data: {
                            breadcrumb: 'profile',
                            requiredPermission: {
                                category: PermissionCategory.CLIENTS,
                                action: PermissionAction.VIEW,
                            },
                        },
                    },
                    {
                        path: 'product',
                        canActivate: [PermissionGuard],
                        loadComponent: () =>
                            import(
                                './pages/clients/view-client/assign-product/assign-product.component'
                            ).then((m) => m.AssignProductComponent),
                        data: {
                            breadcrumb: 'profile',
                            requiredPermission: {
                                category: PermissionCategory.CLIENTS,
                                action: PermissionAction.MANAGE,
                            },
                        },
                    },
                    {
                        path: 'product/unassign',
                        canActivate: [PermissionGuard],
                        loadComponent: () =>
                            import(
                                './pages/clients/view-client/unassign-product/unassign-product.component'
                            ).then((m) => m.UnassignProductComponent),
                        data: {
                            breadcrumb: 'profile',
                            requiredPermission: {
                                category: PermissionCategory.CLIENTS,
                                action: PermissionAction.MANAGE,
                            },
                        },
                    },
                    {
                        path: 'user',
                        canActivate: [PermissionGuard],
                        loadComponent: () =>
                            import(
                                './pages/clients/view-client/view-user/view-user.component'
                            ).then((m) => m.ViewUserComponent),
                        data: {
                            breadcrumb: 'profile',
                            requiredPermission: {
                                category: PermissionCategory.CLIENTS,
                                action: PermissionAction.MANAGE,
                            },
                        },
                    },
                ],
            },
        ],
    },
    {
        path: 'role',
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'Role',
        },
        children: [
            {
                path: '',
                canActivate: [PermissionGuard],
                data: {
                    breadcrumb: 'List',
                    requiredPermission: {
                        category: PermissionCategory.ROLES,
                        action: PermissionAction.VIEW,
                    },
                },
                loadComponent: () =>
                    import('./pages/roles/roles.component').then(
                        (m) => m.RolesComponent
                    ),
            },
            {
                path: 'view/:id',
                canActivate: [PermissionGuard],
                data: {
                    breadcrumb: 'View',
                    requiredPermission: {
                        category: PermissionCategory.ROLES,
                        action: PermissionAction.VIEW,
                    },
                },
                loadComponent: () =>
                    import(
                        './pages/roles/manage-role//manage-role.component'
                    ).then((m) => m.ManageRoleComponent),
            },
            {
                path: 'edit/:id',
                canActivate: [PermissionGuard],
                data: {
                    breadcrumb: 'Edit',
                    requiredPermission: {
                        category: PermissionCategory.ROLES,
                        action: PermissionAction.MANAGE,
                    },
                },
                loadComponent: () =>
                    import(
                        './pages/roles/manage-role//manage-role.component'
                    ).then((m) => m.ManageRoleComponent),
            },
            {
                path: 'add',
                canActivate: [PermissionGuard],
                data: {
                    breadcrumb: 'Add',
                    requiredPermission: {
                        category: PermissionCategory.ROLES,
                        action: PermissionAction.MANAGE,
                    },
                },
                loadComponent: () =>
                    import(
                        './pages/roles/manage-role//manage-role.component'
                    ).then((m) => m.ManageRoleComponent),
            },
            {
                path: 'permission/:id',
                canActivate: [PermissionGuard],
                data: {
                    breadcrumb: 'Permission',
                    requiredPermission: {
                        category: PermissionCategory.ROLES,
                        action: PermissionAction.VIEW,
                    },
                },
                loadComponent: () =>
                    import(
                        './pages/roles/role-permission//role-permission.component'
                    ).then((m) => m.RolePermissionComponent),
            },
        ],
    },
    {
        path: 'attribute',
        canActivate: [AuthGuard, PermissionGuard],
        data: {
            breadcrumb: 'Attributes',
            requiredPermission: {
                category: PermissionCategory.PRODUCTS,
                action: PermissionAction.MANAGE,
            },
        },
        children: [
            {
                path: '',
                canActivate: [PermissionGuard],
                loadComponent: () =>
                    import('./pages/attributes/attributes.component').then(
                        (m) => m.AttributesComponent
                    ),
                data: {
                    breadcrumb: 'List',
                    requiredPermission: {
                        category: PermissionCategory.PRODUCTS,
                        action: PermissionAction.MANAGE,
                    },
                },
            },
        ],
    },
    {
        path: 'product-group',
        canActivate: [AuthGuard, PermissionGuard],
        data: {
            breadcrumb: 'Product Group',
            requiredPermission: {
                category: PermissionCategory.PRODUCTS,
                action: PermissionAction.MANAGE,
            },
        },
        children: [
            {
                path: '',
                canActivate: [PermissionGuard],
                loadComponent: () =>
                    import(
                        './pages/product-group/product-group.component'
                    ).then((m) => m.ProductGroupComponent),
                data: {
                    breadcrumb: 'List',
                    requiredPermission: {
                        category: PermissionCategory.PRODUCTS,
                        action: PermissionAction.MANAGE,
                    },
                },
            },
            {
                path: 'assignment/:id',
                canActivate: [PermissionGuard],
                loadComponent: () =>
                    import(
                        './pages/product-group/assign-product-group/assign-product-group.component'
                    ).then((m) => m.AssignProductGroupComponent),
                data: {
                    breadcrumb: 'Assignment List',
                    requiredPermission: {
                        category: PermissionCategory.PRODUCTS,
                        action: PermissionAction.MANAGE,
                    },
                },
            },
            {
                path: 'unassignment/:id',
                canActivate: [PermissionGuard],
                loadComponent: () =>
                    import(
                        './pages/product-group/unassign-product-group/unassign-product-group.component'
                    ).then((m) => m.UnassignProductGroupComponent),
                data: {
                    breadcrumb: 'Unassignment List',
                    requiredPermission: {
                        category: PermissionCategory.PRODUCTS,
                        action: PermissionAction.MANAGE,
                    },
                },
            },
        ],
    },
    {
        path: 'category',
        canActivate: [AuthGuard, PermissionGuard],
        data: {
            breadcrumb: 'Category',
            requiredPermission: {
                category: PermissionCategory.PRODUCTS,
                action: PermissionAction.MANAGE,
            },
        },
        children: [
            {
                path: '',
                canActivate: [PermissionGuard],
                loadComponent: () =>
                    import('./pages/categories/categories.component').then(
                        (m) => m.CategoriesComponent
                    ),
                data: {
                    breadcrumb: 'List',
                    requiredPermission: {
                        category: PermissionCategory.PRODUCTS,
                        action: PermissionAction.MANAGE,
                    },
                },
            },
        ],
    },
    {
        path: 'embellishment',
        canActivate: [AuthGuard, PermissionGuard],
        data: {
            breadcrumb: 'Embellishment',
            requiredPermission: {
                category: PermissionCategory.PRODUCTS,
                action: PermissionAction.MANAGE,
            },
        },
        children: [
            {
                path: '',
                canActivate: [PermissionGuard],
                loadComponent: () =>
                    import(
                        './pages/embellishment/embellishment.component'
                    ).then((m) => m.EmbellishmentComponent),
                data: {
                    breadcrumb: 'List',
                    requiredPermission: {
                        category: PermissionCategory.PRODUCTS,
                        action: PermissionAction.MANAGE,
                    },
                },
            },
            {
                path: 'edit/:id',
                canActivate: [PermissionGuard],
                loadComponent: () =>
                    import(
                        './pages/embellishment/manage-embellishment/manage-embellishment.component'
                    ).then((m) => m.ManageEmbellishmentComponent),
                data: {
                    breadcrumb: 'Edit Embellishment',
                    requiredPermission: {
                        category: PermissionCategory.PRODUCTS,
                        action: PermissionAction.MANAGE,
                    },
                },
            },
            {
                path: 'add',
                canActivate: [PermissionGuard],
                loadComponent: () =>
                    import(
                        './pages/embellishment/manage-embellishment/manage-embellishment.component'
                    ).then((m) => m.ManageEmbellishmentComponent),
                data: {
                    breadcrumb: 'Add Embellishment',
                    requiredPermission: {
                        category: PermissionCategory.PRODUCTS,
                        action: PermissionAction.MANAGE,
                    },
                },
            },
            {
                path: 'view/:id',
                canActivate: [PermissionGuard],
                loadComponent: () =>
                    import(
                        './pages/embellishment/manage-embellishment/manage-embellishment.component'
                    ).then((m) => m.ManageEmbellishmentComponent),
                data: {
                    breadcrumb: 'View Embellishment',
                    requiredPermission: {
                        category: PermissionCategory.PRODUCTS,
                        action: PermissionAction.MANAGE,
                    },
                },
            },
        ],
    },
    {
        path: 'product',
        canActivate: [AuthGuard],
        data: { breadcrumb: 'Product' },
        children: [
            {
                path: '',
                canActivate: [PermissionGuard],
                loadComponent: () =>
                    import('./pages/product/product.component').then(
                        (m) => m.ProductComponent
                    ),
                data: {
                    breadcrumb: 'List',
                    requiredPermission: {
                        category: PermissionCategory.PRODUCTS,
                        action: PermissionAction.VIEW,
                    },
                },
            },
            {
                path: 'add',
                canActivate: [PermissionGuard],
                loadComponent: () =>
                    import(
                        './pages/product/manage-product/manage-product.component'
                    ).then((m) => m.ManageProductComponent),
                data: {
                    breadcrumb: 'Add Product',
                    requiredPermission: {
                        category: PermissionCategory.PRODUCTS,
                        action: PermissionAction.MANAGE,
                    },
                },
            },
            {
                path: 'edit/:id',
                canActivate: [PermissionGuard],
                loadComponent: () =>
                    import(
                        './pages/product/manage-product/manage-product.component'
                    ).then((m) => m.ManageProductComponent),
                data: {
                    breadcrumb: 'Edit Product',
                    requiredPermission: {
                        category: PermissionCategory.PRODUCTS,
                        action: PermissionAction.MANAGE,
                    },
                },
            },
            {
                path: 'view/:id',
                canActivate: [PermissionGuard],
                loadComponent: () =>
                    import(
                        './pages/product/view-product/view-product.component'
                    ).then((m) => m.ViewProductComponent),
                data: {
                    breadcrumb: 'View Product',
                    requiredPermission: {
                        category: PermissionCategory.PRODUCTS,
                        action: PermissionAction.VIEW,
                    },
                },
            },
        ],
    },
    {
        path: 'order',
        canActivate: [AuthGuard],
        data: { breadcrumb: 'Order' },
        children: [
            {
                path: '',
                pathMatch: 'full',
                loadComponent: () =>
                    import(
                        './pages/order/order-list/order-list.component'
                    ).then((m) => m.OrderListComponent),
                data: {
                    breadcrumb: 'View Order',
                },
            },
            {
                path: 'view/:id',

                loadComponent: () =>
                    import(
                        './pages/order/order-view/order-view.component'
                    ).then((m) => m.OrderViewComponent),
                data: {
                    breadcrumb: 'View Order',
                },
                children: [
                    {
                        path: '',

                        loadComponent: () =>
                            import(
                                './pages/order/order-view/order-details/order-details.component'
                            ).then((m) => m.OrderDetailsComponent),
                        data: {
                            breadcrumb: 'Order Details',
                        },
                    },
                    {
                        path: 'log',

                        loadComponent: () =>
                            import(
                                './pages/order/order-view/order-logs/order-logs.component'
                            ).then((m) => m.OrderLogsComponent),
                        data: {
                            breadcrumb: 'Order Logs',
                        },
                    },
                    {
                        path: 'invoices',

                        loadComponent: () =>
                            import(
                                './pages/order/order-view/order-invoices/order-invoices.component'
                            ).then((m) => m.OrderInvoicesComponent),
                        data: {
                            breadcrumb: 'Order Invoices',
                        },
                    },
                ],
            },
        ],
    },
    {
        path: '',
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                pathMatch: 'full',
                loadComponent: () =>
                    import(
                        './pages/order/order-list/order-list.component'
                    ).then((m) => m.OrderListComponent),
            },
        ],
    },
    {
        path: 'internal-error',
        loadComponent: () =>
            import(
                './core/layouts/internal-error/internal-error.component'
            ).then((m) => m.InternalErrorComponent),
    },
    {
        path: 'invoices',
        canActivate: [AuthGuard],
        loadComponent: () =>
            import(
                './pages/invoice/invoice-list/invoice.component'
            ).then((m) => m.InvoiceComponent),
            data: {
                breadcrumb: 'Invoice List',
            },
    },
    {
        path: 'invoice',
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                pathMatch: 'full',
                loadComponent: () =>
                    import(
                        './pages/invoice/invoice-list/invoice.component'
                    ).then((m) => m.InvoiceComponent),
                    data: {
                        breadcrumb: 'Invoice List',
                    },
            },
            {
                path: 'view/:id',
                loadComponent: () =>
                    import(
                        './pages/invoice/invoice-view/invoice-view.component'
                    ).then((m) => m.InvoiceViewComponent),
                    data: {
                        breadcrumb: 'Invoice',
                    },
            },
        ],
    },
    {
        path: '**',
        loadComponent: () =>
            import('./core/layouts/not-found/not-found.component').then(
                (m) => m.NotFoundComponent
            ),
    },
];
