<header
    class="header-area bg-white border-radius transition"
    [ngClass]="{'active': isSidebarToggled, 'sticky': isSticky}"
    [class.component-dark-theme]="themeService.isDark()"
    [class.right-sidebar]="themeService.isRightSidebar()"
    [class.hide-sidebar]="themeService.isHideSidebar()"
    [class.dark-header]="themeService.isHeaderDark()"
    [class.card-borderd-theme]="themeService.isCardBorder()"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="d-md-flex align-items-center justify-content-between">
        <div class="header-left-side d-flex align-items-center">
            <div
                class="burger-menu cursor-pointer transition d-xl-none"
                [ngClass]="{'active': isSidebarToggled}"
                (click)="toggle()"
            >
                <span class="top-bar d-block bg-black transition"></span>
                <span class="middle-bar d-block bg-black transition"></span>
                <span class="bottom-bar d-block bg-black transition"></span>
            </div>
            <form class="search-box position-relative">
                <i class="material-symbols-outlined">
                    search
                </i>
                <input type="text" class="input-search d-block w-100 border-none outline-0" placeholder="Search here...">
            </form>
        </div>
        <ul class="header-right-side d-flex align-items-center mt-0 mb-0 pl-0 list-unstyled">
            <li>
                <button mat-icon-button [matMenuTriggerFor]="languageMenu" class="language-menu-btn">
                    <i class="material-symbols-outlined">
                        translate
                    </i>
                </button>
                <mat-menu class="language-menu-dropdown" #languageMenu="matMenu" xPosition="before">
                    <div
                        [class.dark-menu]="themeService.isDark() || themeService.isHeaderDark()"
                    >
                        <span class="title fw-medium d-block">Choose Language</span>
                        <ul class="menu-body pl-0 mb-0 mt-0 list-unstyled">
                            <li>
                                <button mat-button>
                                    <img src="assets/images/flags/usa.png" alt="flag">
                                    English
                                </button>
                            </li>
                            <li>
                                <button mat-button>
                                    <img src="assets/images/flags/australia.png" alt="flag">
                                    Australian
                                </button>
                            </li>
                            <li>
                                <button mat-button>
                                    <img src="assets/images/flags/spain.png" alt="flag">
                                    Spanish
                                </button>
                            </li>
                            <li>
                                <button mat-button>
                                    <img src="assets/images/flags/france.png" alt="flag">
                                    French
                                </button>
                            </li>
                            <li>
                                <button mat-button>
                                    <img src="assets/images/flags/germany.png" alt="flag">
                                    German
                                </button>
                            </li>
                        </ul>
                    </div>
                </mat-menu>
            </li>
            <li>
                <button
                    mat-button
                    class="dark-swtich-btn"
                    (click)="toggleTheme()"
                >
                    <i class="material-symbols-outlined">
                        dark_mode
                    </i>
                </button>
            </li>
            <li>
                <a routerLink="/calendar" mat-icon-button class="calendar-menu-btn p-0">
                    <i class="material-symbols-outlined">
                        calendar_today
                    </i>
                </a>
            </li>
            <li>
                <button mat-icon-button [matMenuTriggerFor]="messagesMenu" class="messages-menu-btn p-0">
                    <i class="material-symbols-outlined">
                        mail
                    </i>
                    <span class="daxa-badge">
                        5
                    </span>
                </button>
                <mat-menu class="messages-menu-dropdown" #messagesMenu="matMenu" xPosition="before">
                    <div
                        [class.dark-menu]="themeService.isDark() || themeService.isHeaderDark()"
                    >
                        <div class="title d-flex align-items-center justify-content-between">
                            <span class="fw-medium">
                                Messages 
                                <span class="text-body fw-normal">(05)</span>
                            </span>
                            <a routerLink="/chat" class="link-btn text-daxa">
                                Mark all as read
                            </a>
                        </div>
                        <ul class="menu-body pl-0 mt-0 list-unstyled">
                            <li class="position-relative">
                                <img src="assets/images/users/user1.jpg" width="44" height="44" class="rounded-circle position-absolute" alt="user">
                                <span class="sub-title d-block fw-medium">
                                    Jacob Liwiski <span class="text-body fw-normal">35 mins ago</span>
                                </span>
                                <span class="message d-block text-body">
                                    Hey Victor! Could you please...
                                </span>
                                <a routerLink="/chat" class="d-block link-btn position-absolute"></a>
                                <span class="unread d-inline-block rounded-circle bg-daxa position-absolute"></span>
                            </li>
                            <li class="position-relative">
                                <img src="assets/images/users/user2.jpg" width="44" height="44" class="rounded-circle position-absolute" alt="user">
                                <span class="sub-title d-block fw-medium">
                                    Angela Carter <span class="text-body fw-normal">1 day ago</span>
                                </span>
                                <span class="message d-block text-body">
                                    How are you Angela? Would you please...
                                </span>
                                <a routerLink="/chat" class="d-block link-btn position-absolute"></a>
                            </li>
                            <li class="position-relative">
                                <img src="assets/images/users/user3.jpg" width="44" height="44" class="rounded-circle position-absolute" alt="user">
                                <span class="sub-title d-block fw-medium">
                                    Brad Traversy  <span class="text-body fw-normal">2 days ago</span>
                                </span>
                                <span class="message d-block text-body">
                                    Hey Brad Traversy! Could you please...
                                </span>
                                <a routerLink="/chat" class="d-block link-btn position-absolute"></a>
                            </li>
                        </ul>
                        <div class="menu-footer text-center">
                            <a routerLink="/chat" class="link-btn text-daxa d-inline-block fw-medium">
                                View All Messages
                            </a>
                        </div>
                    </div>
                </mat-menu>
            </li>
            <li>
                <button mat-icon-button [matMenuTriggerFor]="notificationsMenu" class="notifications-menu-btn p-0">
                    <i class="material-symbols-outlined">
                        notifications
                    </i>
                    <span class="daxa-badge">
                        3
                    </span>
                </button>
                <mat-menu class="notifications-menu-dropdown" #notificationsMenu="matMenu" xPosition="before">
                    <div
                        [class.dark-menu]="themeService.isDark() || themeService.isHeaderDark()"
                    >
                        <div class="title d-flex align-items-center justify-content-between">
                            <span class="fw-medium">
                                Notifications
                                <span class="text-body fw-normal">(03)</span>
                            </span>
                            <a routerLink="/notifications" class="link-btn text-daxa">
                                Clear All
                            </a>
                        </div>
                        <ul class="menu-body pl-0 mt-0 list-unstyled">
                            <li class="position-relative">
                                <div class="icon rounded-circle position-absolute text-center transition">
                                    <i class="material-symbols-outlined">
                                        sms
                                    </i>
                                </div>
                                <span class="sub-title d-block">
                                    You have requested to <strong class="fw-medium">withdrawal</strong>
                                </span>
                                <span class="time d-block text-body">
                                    2 hrs ago
                                </span>
                                <a routerLink="/notifications" class="d-block link-btn position-absolute"></a>
                                <span class="unread d-inline-block rounded-circle bg-daxa position-absolute"></span>
                            </li>
                            <li class="position-relative">
                                <div class="icon rounded-circle position-absolute text-center transition">
                                    <i class="material-symbols-outlined">
                                        person
                                    </i>
                                </div>
                                <span class="sub-title d-block">
                                    <strong class="fw-medium">A new user</strong> added in Daxa
                                </span>
                                <span class="time d-block text-body">
                                    3 hrs ago
                                </span>
                                <a routerLink="/notifications" class="d-block link-btn position-absolute"></a>
                                <span class="unread d-inline-block rounded-circle bg-daxa position-absolute"></span>
                            </li>
                            <li class="position-relative">
                                <div class="icon rounded-circle position-absolute text-center transition">
                                    <i class="material-symbols-outlined">
                                        mark_email_unread
                                    </i>
                                </div>
                                <span class="sub-title d-block">
                                    You have requested to <strong class="fw-medium">withdrawal</strong>
                                </span>
                                <span class="time d-block text-body">
                                    1 day ago
                                </span>
                                <a routerLink="/notifications" class="d-block link-btn position-absolute"></a>
                            </li>
                        </ul>
                        <div class="menu-footer text-center">
                            <a routerLink="/notifications" class="link-btn text-daxa d-inline-block fw-medium">
                                See All Notifications
                            </a>
                        </div>
                    </div>
                </mat-menu>
            </li>
            <li>
                <button mat-button [matMenuTriggerFor]="profileMenu" class="profile-menu-btn">
                    <img src="assets/images/admin.png" alt="profile-image">
                    <span class="status d-inline-block rounded-circle position-absolute"></span>
                </button>
                <mat-menu class="profile-menu-dropdown" #profileMenu="matMenu" xPosition="before">
                    <div
                        [class.dark-menu]="themeService.isDark() || themeService.isHeaderDark()"
                    >
                        <div class="menu-header d-flex align-items-center">
                            <img src="assets/images/admin.png" alt="profile-image">
                            <div class="title">
                                <a routerLink="/my-profile" class="d-inline-block fw-medium">
                                    Mateo Luca
                                </a>
                                <span class="designation d-block text-body">
                                    Admin
                                </span>
                            </div>
                        </div>
                        <ul class="menu-body pl-0 mb-0 mt-0 list-unstyled">
                            <li class="position-relative transition fw-medium">
                                <i class="material-symbols-outlined">
                                    person
                                </i>
                                My Profile
                                <a routerLink="/my-profile" class="link-btn d-block position-absolute"></a>
                            </li>
                            <li class="position-relative transition fw-medium">
                                <i class="material-symbols-outlined">
                                    settings
                                </i>
                                Settings
                                <a routerLink="/settings" class="link-btn d-block position-absolute"></a>
                            </li>
                            <li class="position-relative transition fw-medium">
                                <i class="material-symbols-outlined">
                                    info
                                </i>
                                Support
                                <a routerLink="/settings/connections" class="link-btn d-block position-absolute"></a>
                            </li>
                            <li class="position-relative transition fw-medium">
                                <i class="material-symbols-outlined">
                                    logout
                                </i>
                                Logout
                                <a routerLink="/logout" class="link-btn d-block position-absolute"></a>
                            </li>
                        </ul>
                    </div>
                </mat-menu>
            </li>
        </ul>
    </div>
</header>