import { Action, State, createReducer, on } from '@ngrx/store';
import { User, loginAction, logoutAction } from '../actions/auth.actions';

export const initialLoginState: User = {
    id: 0,
    emailAddress: '',
    firstName: '',
    lastName: '',
    status: '',
    profileImage: '',
};

const _userReducer = createReducer(
    initialLoginState,
    on(loginAction, (state, { user }) => {
        return {
            id: user.id,
            emailAddress: user.emailAddress,
            firstName: user.firstName,
            lastName: user.lastName,
            status: user.status,
            profileImage: user.profileImage,
        };
    }),
    on(logoutAction, (state) => {
        return {
            id: 0,
            emailAddress: '',
            firstName: '',
            lastName: '',
            status: '',
            profileImage: '',
        };
    })
);

export function userReducer(state: User | undefined, action: Action): User {
    return _userReducer(state, action);
}
