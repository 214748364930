import { LoaderService } from './../../shared/components/loader/loader.service';
import { Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import {
    Observable,
    catchError,
    finalize,
    switchMap,
    tap,
    throwError,
} from 'rxjs';
import { StorageManagerService, Token } from './storage-manager.service';
import { AuthenticationService } from './authentication.service';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(
        private loaderService: LoaderService,
        private authService: AuthenticationService,
        private _router: Router
    ) {}
    TOKEN = StorageManagerService.getToken();
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (StorageManagerService.getToken()) {
            request = this.addAuthenticationToken(
                request,
                StorageManagerService.getToken()
            );
            this.loaderService.show();
            return next.handle(request).pipe(
                catchError((error) => {
                    if (
                        error instanceof HttpErrorResponse &&
                        error.status === 401
                    ) {
                        return this.handle401Error(request, next, this.TOKEN);
                    } else {
                        return throwError(error);
                    }
                }),
                finalize(() => {
                    this.loaderService.hide();
                })
            );
        }
        return next.handle(request);
    }

    public addAuthenticationToken(
        request: HttpRequest<any>,
        accessToken: { token: string; refreshToken: string } | null
    ): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${
                    accessToken?.token
                        ? accessToken.token
                        : StorageManagerService.getToken().token
                }`,
            },
        });
    }

    public handle401Error(
        request: HttpRequest<any>,
        next: HttpHandler,
        accessToken: { token: string; refreshToken: string }
    ): Observable<any> {
        return this.authService
            .refreshToken({ refresh_token: accessToken.refreshToken })
            .pipe(
                catchError((error) => {
                    this.authService.logout();
                    this._router.navigate(['/', 'logout']);
                    return throwError(error);
                }),
                switchMap(
                    (getRefreshTokenResponse: {
                        data: {
                            token: string;
                            refreshToken: string;
                        };
                        success: boolean;
                    }) => {
                        if (getRefreshTokenResponse.success) {
                            const currentTimeEpoch = Math.floor(
                                Date.now() / 1000
                            );
                            StorageManagerService.storeToken({
                                token: getRefreshTokenResponse?.data.token,
                                refreshToken:
                                    getRefreshTokenResponse?.data.refreshToken,
                                expiredTime: currentTimeEpoch,
                            });
                            return next.handle(
                                this.addAuthenticationToken(
                                    request,
                                    getRefreshTokenResponse.data
                                )
                            );
                        } else {
                            localStorage.clear();
                            this._router.navigate(['/', 'login']);
                        }
                        return throwError(getRefreshTokenResponse);
                    }
                )
            );
    }
}
