import { Component } from '@angular/core';
import { LoaderService } from './loader.service';
import { CommonModule, NgClass } from '@angular/common';

@Component({
    selector: 'app-loader',
    standalone: true,
    imports: [NgClass, CommonModule],
    templateUrl: './loader.component.html',
    styleUrl: './loader.component.scss',
})
export class LoaderComponent {
    isToggled = false;
    constructor(public loaderService: LoaderService) {
        this.loaderService.isToggled$.subscribe((isToggled) => {
            setTimeout(() => {
                this.isToggled = isToggled;
            });
        });
    }
}
