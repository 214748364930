import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import * as Sentry from '@sentry/angular';
import { environment } from './environments/environment';

Sentry.init({
    dsn: environment.sentry_DSN,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
        Sentry.replayIntegration(),
        Sentry.browserApiErrorsIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [environment.api_url],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    profilesSampleRate: 1.0,
});

bootstrapApplication(AppComponent, appConfig).catch((err) =>
    console.error(err)
);
