import { Action, State, createReducer, on } from '@ngrx/store';
import { Config, configAction } from '../actions/setting.reducer';

export const initialSettingState: Config = {
    country: [],
    currency: [],
    timezone: [],
};

const _settingReducer = createReducer(
    initialSettingState,
    on(configAction, (state, { config }) => {
        return {
            country: config.country,
            currency: config.currency,
            timezone: config.timezone,
        };
    })
);

export function settingReducer(
    state: Config | undefined,
    action: Action
): Config {
    return _settingReducer(state, action);
}
