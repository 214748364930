<div
    [ngClass]="{
        'blank-page':
            router.url === '/login' ||
            router.url === '/sign-up' ||
            router.url === '/forgot-password' ||
            router.url.includes('/reset-password/') ||
            router.url === '/lock-screen' ||
            router.url === '/logout' ||
            router.url === '/confirm-email' ||
            router.url === '/coming-soon'
    }"
    [class.dark-theme]="themeService.isDark()"
    [class.card-borderd-theme]="themeService.isCardBorder()"
    [class.card-border-radius]="themeService.isCardBorderRadius()"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <!-- Sidebar -->
    @if((user$ | async)?.id) {
    <app-sidebar></app-sidebar>
    }

    <!-- Main Content -->
    <div
        class="main-content transition d-flex flex-column"
        [ngClass]="{ active: isSidebarToggled }"
        [class.right-sidebar]="themeService.isRightSidebar()"
        [class.hide-sidebar]="themeService.isHideSidebar()"
    >
        @if((user$ | async)?.id) {
        <div style="position: absolute; right: 0; padding-right: 25px">
            <app-breadcrumb></app-breadcrumb>
        </div>
        }
        <router-outlet></router-outlet>
        @if((user$ | async)?.id) {
        <div class="flex-grow-1"></div>

        <app-footer></app-footer>
        }
    </div>

    <!-- Customizer Settings -->
    <!-- <app-customizer-settings></app-customizer-settings> -->
    <!-- Customizer Settings -->

    <app-loader></app-loader>
</div>
